.landing {
  margin: 48px auto 0;
  max-width: 700px;
}

button.play {
  background-color: var(--green);
  border: 2px solid var(--green-dark);
  border-radius: 30px;
  color: var(--white);
  font-size: 24px;
  font-weight: 500;
  height: 62px;
  margin-bottom: 30px;
  width: 235px;

  &:disabled {
    background-color: var(--grey);
    cursor: default;
  }

  &:hover:enabled {
    background-color: var(--green-hover);
  }
}

.landingStats {
  border: 1px solid var(--black);
  margin: 0 auto;
  max-width: 300px;
  padding: 20px;
}
