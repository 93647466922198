.balance {
  margin: 24px auto 0;
}

.betBox {
  span {
    font-weight: 600;
    margin-right: 15px;
  }

  input {
    font-size: 18px;
    height: 30px;
    padding: 0 10px;
    width: 150px;
  }
}

.balanceBox {
  padding: 24px;

  span:first-child {
    font-weight: 600;
    margin-right: 10px;
  }

  span:last-child {
    font-size: 20px;
  }
}
