.rules {
  padding: 0 15px;
  text-align: center;

  h3 {
    margin: 0 0 10px 0;
  }

  p {
    margin: 0;
  }

  ul {
    padding-left: 0;
    text-align: left;
  }

  li {
    line-height: 24px;
  }

  span {
    font-weight: 700;
  }
}

.list {
  display: inline-block;

  &:last-child ul {
    margin-top: 0;
  }
}

.green {
  color: var(--green);
}
