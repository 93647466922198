.app {
  display: flex;
  flex-direction: column;
  min-height: -webkit-fill-available;
  min-height: 100vh;
}

.page {
  flex-grow: 1;
  padding: 25px 0;
  text-align: center;

  @media screen and (min-width: 480px) {
    padding: 50px 0 15px;
  }
}

.content {
  padding: 15px 0 30px;
  text-align: center;
}
